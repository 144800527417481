import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Loading, SensitiveTextInput } from '@epcbuilder/lib/components';
import Modal from '@epcbuilder/lib/components/Modal';
import { ResetPassword } from '@epcbuilder/lib/models/auth';
import { handleFormErrors, passwordValidationSchema } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { patchUserPassword } from '@/network/users';

const changePasswordSchema = yup.object().shape({
  password: passwordValidationSchema,
  confirmPassword: yup
    .string()
    .required('Confirm Password must not be empty')
    .oneOf([yup.ref('password'), ''], 'Confirm Password must match Password'),
});

const ChangePasswordModal = ({ id, onClose }: { id: string; onClose: () => void }) => {
  const defaultValues: ResetPassword = {
    password: '',
    confirmPassword: '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<ResetPassword>({
    defaultValues,
    resolver: yupResolver(changePasswordSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<ResetPassword> = async (data) => {
    try {
      await patchUserPassword({ id, password: data.password });
      toast.success('User password successfully updated', { toastId: 'change-password-success' });
      onClose();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<ResetPassword>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  if (isSubmitting) {
    return <Loading />;
  }

  return (
    <Modal id="password-modal" onClose={onClose}>
      <h1>Change User Password</h1>
      <form className="mt-4 flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <SensitiveTextInput
          {...register('password')}
          id="password"
          name="password"
          title="Users new password"
          placeholder="New Password"
          error={errors.password?.message}
        />
        <SensitiveTextInput
          {...register('confirmPassword')}
          id="confirmPassword"
          name="confirmPassword"
          title="Confirm users new password"
          placeholder="Confirm New Password"
          error={errors.confirmPassword?.message}
        />
        <div className="flex flex-row gap-4">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button id="change-password-submit" loading={isSubmitting} type="submit">
            Update
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ChangePasswordModal;
