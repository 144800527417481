import * as jose from 'jose';
import { ActionMap } from './generic';

export enum AuthContextActionTypes {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  SET_PRE_LOGOUT_ACTION = 'SET_PRE_LOGOUT_ACTION',
}

export type AuthContextState = {
  isAuthenticated: boolean;
  userId?: string;
  preLogoutAction?: () => void | null;
};

export type AuthContextPayload = {
  [AuthContextActionTypes.LOGIN]: {
    token: string;
    refreshToken: string;
    userId: string;
    rememberMe?: boolean;
  };
  [AuthContextActionTypes.LOGOUT]: undefined;
  [AuthContextActionTypes.SET_PRE_LOGOUT_ACTION]: () => void;
};

export type AuthContextActions = ActionMap<AuthContextPayload>[keyof ActionMap<AuthContextPayload>];

export type AuthContextType = {
  state: AuthContextState;
  dispatchLogin: (payload: AuthContextPayload[AuthContextActionTypes.LOGIN]) => void;
  dispatchLogout: () => void;
  setPreLogoutAction: (action: () => void) => void;
};

export type Token = jose.JWTPayload & {
  ID: string;
  FirstName: string;
  lastName: string;
};
