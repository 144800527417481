import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { MdClose } from 'react-icons/md';
import { twMerge } from 'tailwind-merge';
import { Children } from '../utils';
import { Button, NewButton } from './Buttons';

const modalRoot =
  typeof document !== 'undefined' ? document.getElementById('modal-root') || document.createElement('div') : null;

if (modalRoot) {
  modalRoot.id = 'modal-root';
  document.body.appendChild(modalRoot);
}

const Modal = ({
  onClose,
  id,
  maxWidth,
  children,
  containerClassName,
  className,
  closeButtonClassName,
}: {
  onClose: () => void;
  id?: string;
  maxWidth?: string;
  children: Children;
  containerClassName?: string;
  className?: string;
  closeButtonClassName?: string;
}) => {
  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    document.body.style.paddingRight = '5px';

    return () => {
      document.body.style.overflowY = 'scroll';
      document.body.style.paddingRight = '0';
    };
  }, []);

  if (!modalRoot) return;

  const baseContainerClassName = 'fixed inset-0 z-50 flex sm:items-center sm:justify-center';
  const finalContainerClassName = twMerge(baseContainerClassName, containerClassName);

  const baseClassName = `${maxWidth || ''} dark:bg-dark-dark px-8 pb-8 relative flex h-screen w-screen flex-col overflow-auto bg-[#F8F9FA] shadow sm:size-auto sm:max-h-[min(650px,90vh)] sm:w-full sm:max-w-lg sm:rounded-xl`;
  const finalClassName = twMerge(baseClassName, className);

  const baseCloseButtonClassName = 'sticky top-0 z-10 cursor-pointer p-2 pt-2 text-color flex justify-end -mr-8';
  const finalCloseButtonClassName = twMerge(baseCloseButtonClassName, closeButtonClassName);

  return createPortal(
    <div className={finalContainerClassName}>
      <button
        type="button"
        className="bg-dark fixed inset-0 flex items-center justify-center opacity-[0.56] dark:bg-[#DCE1E3]"
        onClick={onClose}
      />
      <div id={id || 'modal'} className={`${finalClassName} hide-scrollbar`}>
        <button aria-label="Close modal" type="button" className={finalCloseButtonClassName} onClick={onClose}>
          <MdClose size={24} />
        </button>
        {children}
      </div>
    </div>,
    modalRoot
  );
};

export default Modal;

export const RemoveUserFromCompanyModal = ({
  value,
  onClose,
  callback,
}: {
  value: string | undefined;
  onClose: () => void;
  callback: () => Promise<void>;
}) => {
  return (
    <Modal id="remove-user-from-company-modal" onClose={onClose}>
      <h1 className="font-header text-lg">Are you sure?</h1>
      <p className="mt-4">
        You are about to remove <span className="font-bold">{value}</span> from the company
      </p>
      <div className="mt-8 flex flex-row justify-between gap-4">
        <div className="w-48">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
        </div>
        <div className="w-48">
          <Button
            id="delete-confirm"
            style="delete"
            onClick={async () => {
              await callback();
              onClose();
            }}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const DeleteModal = ({
  value,
  onClose,
  callback,
}: {
  value: string | undefined;
  onClose: () => void;
  callback: () => Promise<void>;
}) => {
  return (
    <Modal id="delete-modal" onClose={onClose}>
      <h1 className="font-header text-lg">Are you sure?</h1>
      <p className="mt-4">
        You are about to delete <span className="font-bold">{value}</span>
      </p>
      <div className="mt-8 flex flex-row justify-between gap-4">
        <NewButton id="delete-cancel" variant="secondary" text="Cancel" onClick={onClose} />
        <NewButton
          id="delete-confirm"
          variant="delete"
          text="Delete"
          onClick={async () => {
            await callback();
            onClose();
          }}
        />
      </div>
    </Modal>
  );
};
