import React from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { MdDone } from 'react-icons/md';
import ReactSelect, { components, MultiValueProps, OptionProps } from 'react-select';
import { twMerge } from 'tailwind-merge';
import { Option } from '../utils/generic';

type OptionType = { label: string; value: boolean | number | string };

const MultiValue = ({ index, getValue }: MultiValueProps<OptionType, true>) => {
  const values = getValue();

  if (values.length === 1) {
    const value = values[0];
    return <div>{String(value.label)}</div>;
  }
  if (index === 0 && values.length > 1) {
    return <div>{`${values.length} items selected`}</div>;
  }

  return null;
};

const CustomOption = (props: OptionProps<OptionType, true>) => {
  const { isSelected, label } = props;
  return (
    <components.Option {...props}>
      <div className="flex items-center justify-between">
        <span>{label}</span>
        {isSelected && (
          <span>
            <MdDone size={20} className="text-blue-dark dark:text-white" />
          </span>
        )}
      </div>
    </components.Option>
  );
};

export const Select = <T extends FieldValues>({
  id,
  control,
  name,
  label,
  placeholder,
  options,
  error,
  inline = false,
  className,
  multiple = false,
}: {
  id: string;
  control: Control<T>;
  title: string;
  name: Path<T>;
  label?: string;
  placeholder?: string;
  options: Option<string | number | boolean>[];
  error?: string;
  inline?: boolean;
  className?: string;
  multiple?: boolean;
}) => {
  const baseClassNames =
    'h-10 w-full flex items-center text-base text-color rounded-xl pl-4 py-2 border-2 bg-color cursor-pointer pr-8';
  return (
    <div className={`relative flex w-full gap-2 ${inline ? 'flex-row items-center' : 'flex-col'}`}>
      {label && <p className="ml-2">{label}</p>}
      <div className="relative">
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => {
            if (multiple) {
              return (
                <ReactSelect
                  id={id}
                  instanceId={id}
                  isMulti={multiple}
                  options={options}
                  value={value.length > 0 ? options.find((option) => option.value === value) : []}
                  onChange={(options) => onChange(options.map((option) => option.value))}
                  menuPortalTarget={document.body}
                  unstyled={true}
                  placeholder={placeholder}
                  components={{ MultiValue }}
                  maxMenuHeight={246}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    option: (baseStyles) => ({
                      ...baseStyles,
                      cursor: 'pointer',
                    }),
                  }}
                  classNames={{
                    control: (state) =>
                      `${className} ${baseClassNames} ${state.menuIsOpen ? 'border-primary' : 'border-neutral'}`,
                    dropdownIndicator: () => 'absolute right-2 bottom-2 text-color',
                    menu: () => 'bg-color border-blue border-2 rounded-xl overflow-hidden',
                    option: (state) =>
                      `${state.isSelected && 'bg-primary text-light'} px-4 py-2 hover:bg-neutral hover:text-dark`,
                    clearIndicator: () => 'cursor-pointer',
                    noOptionsMessage: () => 'py-2',
                  }}
                />
              );
            }
            return (
              <ReactSelect
                id={id}
                instanceId={id}
                options={options}
                value={options.find((option) => option.value === value)}
                onChange={(option) => onChange(option?.value)}
                menuPortalTarget={document.body}
                unstyled={true}
                placeholder={placeholder}
                maxMenuHeight={246}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  option: (baseStyles) => ({
                    ...baseStyles,
                    cursor: 'pointer',
                  }),
                }}
                classNames={{
                  control: (state) =>
                    `${className} ${baseClassNames} ${state.menuIsOpen ? 'border-blue' : 'border-neutral'}`,
                  dropdownIndicator: () => 'absolute text-color',
                  menu: () => 'bg-color border-blue border-2 rounded-xl overflow-hidden',
                  option: (state) =>
                    `${state.isSelected && 'bg-primary-lighter text-dark'} px-4 py-2 hover:bg-neutral hover:text-dark`,
                  noOptionsMessage: () => 'py-2',
                }}
              />
            );
          }}
        />
      </div>
      {error && (
        <div id={`${name}-error`} className="ml-2 flex flex-col">
          <p className="text-error text-xs">{error}</p>
        </div>
      )}
    </div>
  );
};

//This will be our Select moving forward
export const NewSelect = <T extends FieldValues>({
  id,
  control,
  name,
  placeholder,
  options,
  error,
  inline = false,
  className,
  multiple = false,
  noBackground = false,
  isSearchable = true,
  label,
}: {
  id: string;
  control: Control<T>;
  title?: string;
  name: Path<T>;
  placeholder?: string;
  options: Option<string | number | boolean>[];
  error?: string;
  inline?: boolean;
  className?: string;
  multiple?: boolean;
  noBackground?: boolean;
  isSearchable?: boolean;
  label?: string;
}) => {
  const baseClassName = `h-10 text-color border-2 cursor-pointer pr-8 hover:shadow-grey-sm hover:dark:border-primary hover:border-blue-dark dark:border-primary-darker hover:border-primary-lighter placeholder:text-color rounded-[20px] px-4 outline-none focus:dark:border-[#7AC2B9] ${noBackground ? 'bg-none' : 'bg-color-secondary'}`; // Conditionally apply the background class

  const finalClassName = twMerge(baseClassName, className);

  return (
    <div className={`relative flex gap-2 ${inline ? 'flex-row items-center' : 'flex-col'}`}>
      <div className="relative">
        {label && <p className="mb-2 font-bold">{label}</p>}
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => {
            if (multiple) {
              return (
                <ReactSelect
                  id={id}
                  instanceId={id}
                  isMulti={multiple}
                  options={options}
                  value={value ? options.filter((option) => value.includes(option.value)) : []}
                  onChange={(options) => onChange(options.map((option) => option.value))}
                  menuPortalTarget={document.body}
                  unstyled={true}
                  placeholder={placeholder}
                  components={{ MultiValue, Option: CustomOption }}
                  maxMenuHeight={300}
                  hideSelectedOptions={false}
                  isSearchable={isSearchable}
                  menuPlacement="auto"
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    option: (baseStyles) => ({
                      ...baseStyles,
                      cursor: 'pointer',
                    }),
                  }}
                  classNames={{
                    control: (state) =>
                      `${finalClassName} ${state.menuIsOpen ? 'border-primary' : 'border-blue-lighter'}`,
                    clearIndicator: () => 'absolute right-8 text-color cursor-pointer',
                    dropdownIndicator: () => 'absolute right-2 text-color cursor-pointer',
                    menu: () =>
                      'mt-1 bg-color-secondary border-blue dark:border-primary border-2 rounded-xl overflow-hidden shadow-grey-sm',
                    option: (state) =>
                      `${state.isSelected ? 'text-color' : ''} px-4 py-2 dark:hover:bg-primary hover:bg-blue-lighter`,
                    noOptionsMessage: () => 'py-2',
                  }}
                />
              );
            }
            return (
              <ReactSelect
                id={id}
                instanceId={id}
                options={options}
                value={options.find((option) => option.value === value)}
                onChange={(option) => onChange(option?.value)}
                menuPortalTarget={document.body}
                unstyled={true}
                placeholder={placeholder}
                maxMenuHeight={300}
                menuPlacement="auto"
                isSearchable={isSearchable}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  option: (baseStyles) => ({
                    ...baseStyles,
                    cursor: 'pointer',
                  }),
                }}
                classNames={{
                  control: (state) =>
                    `${finalClassName} ${state.menuIsOpen ? 'border-primary' : 'border-blue-lighter'}`,
                  dropdownIndicator: () => 'absolute right-2 text-color cursor-pointer',
                  singleValue: () => 'text-color mr-6',
                  placeholder: () => 'text-color',
                  menu: () =>
                    'mt-1 bg-color-secondary border-blue dark:border-primary border-2 rounded-xl overflow-hidden shadow-grey-sm',
                  option: (state) =>
                    `${state.isSelected ? 'text-color' : ''} px-4 py-2 dark:hover:bg-primary hover:bg-blue-lighter`,
                }}
              />
            );
          }}
        />
      </div>
      {error && (
        <div id={`${name}-error`} className="ml-2 flex flex-col">
          <p className="text-error text-xs">{error}</p>
        </div>
      )}
    </div>
  );
};
