import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Loading, Modal, SensitiveTextInput, TextInput } from '@epcbuilder/lib/components';
import { RegisterAdmin } from '@epcbuilder/lib/models/auth';
import { EMAIL_REGEX, handleFormErrors, passwordValidationSchema } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { postAdmin } from '@/network/admins';

const registerSchema = yup.object().shape({
  email: yup.string().matches(EMAIL_REGEX, 'Email is not a valid email address').required('Email must not be empty'),
  firstName: yup.string().required('First Name must not be empty'),
  lastName: yup.string().required('Last Name must not be empty'),
  password: passwordValidationSchema,
  confirmPassword: yup
    .string()
    .required('Confirm Password must not be empty')
    .oneOf([yup.ref('password'), ''], 'Confirm Password must match Password'),
});

const CreateNewAdminModal = ({ onClose }: { onClose: () => void }) => {
  const defaultValues: RegisterAdmin = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<RegisterAdmin>({
    defaultValues,
    resolver: yupResolver(registerSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<RegisterAdmin> = async (data) => {
    try {
      await postAdmin({
        email: data.email.trim(),
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        confirmPassword: data.confirmPassword,
      });
      toast.success('Admin successfully created', { toastId: 'create-admin-success' });
      onClose();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<RegisterAdmin>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  if (isSubmitting) {
    return <Loading />;
  }

  return (
    <Modal id="create-admin-modal" onClose={onClose}>
      <h1>Create Admin</h1>
      <form className="mt-4 flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          {...register('email')}
          id="email"
          name="email"
          title="Your email address"
          placeholder="Email"
          error={errors.email?.message}
        />
        <TextInput
          {...register('firstName')}
          id="firstName"
          name="firstName"
          title="Your first name"
          placeholder="First name"
          error={errors.firstName?.message}
        />
        <TextInput
          {...register('lastName')}
          id="lastName"
          name="lastName"
          title="Your last name"
          placeholder="Last name"
          error={errors.lastName?.message}
        />
        <SensitiveTextInput
          {...register('password')}
          id="password"
          name="password"
          title="Your password"
          placeholder="Password"
          error={errors.password?.message}
        />
        <SensitiveTextInput
          {...register('confirmPassword')}
          id="confirmPassword"
          name="confirmPassword"
          title="Confirm your password"
          placeholder="Confirm Password"
          error={errors.confirmPassword?.message}
        />
        <div className="flex flex-row gap-4">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button id="create-admin-submit" loading={isSubmitting} type="submit">
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default CreateNewAdminModal;
