import React, { createContext, useCallback, useReducer } from 'react';
import Cookies from 'js-cookie';
import { useSWRConfig } from 'swr';
import {
  AuthContextActions,
  AuthContextActionTypes,
  AuthContextPayload,
  AuthContextState,
  AuthContextType,
} from '../models/authContext';
import { Children, initToken } from '../utils';

const initialState: AuthContextState = {
  isAuthenticated: false,
  userId: undefined,
};

const reducer = (state: AuthContextState, action: AuthContextActions): AuthContextState => {
  switch (action.type) {
    case AuthContextActionTypes.LOGIN:
      if (action.payload.rememberMe && action.payload.refreshToken) {
        Cookies.set('rememberMeToken', action.payload.refreshToken);
      }
      initToken(`Bearer ${action.payload.token}`);
      sessionStorage.setItem('token', action.payload.token);
      sessionStorage.setItem('refreshToken', action.payload.refreshToken);
      return {
        ...state,
        isAuthenticated: true,
        userId: action.payload.userId,
      };
    case AuthContextActionTypes.LOGOUT:
      if (state.preLogoutAction) state.preLogoutAction();
      Cookies.remove('rememberMeToken');
      initToken(null);
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('refreshToken');
      return {
        ...state,
        isAuthenticated: false,
        userId: undefined,
        preLogoutAction: undefined,
      };
    case AuthContextActionTypes.SET_PRE_LOGOUT_ACTION:
      return {
        ...state,
        preLogoutAction: action.payload,
      };
    default:
      return state;
  }
};

export const AuthContext = createContext<AuthContextType>({
  state: initialState,
  dispatchLogin: () => null,
  dispatchLogout: () => null,
  setPreLogoutAction: () => null,
});

export const AuthProvider = ({ children }: { children: Children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { mutate } = useSWRConfig();
  const preLogoutActionRef = React.useRef<(() => void) | null>(null);

  const clearCache = useCallback(async () => {
    await mutate(() => true, undefined, { revalidate: false });
  }, [mutate]);

  const dispatchLogin = useCallback(
    async (payload: AuthContextPayload[AuthContextActionTypes.LOGIN]) => {
      dispatch({
        type: AuthContextActionTypes.LOGIN,
        payload,
      });
    },
    [dispatch]
  );

  const dispatchLogout = useCallback(async () => {
    dispatch({ type: AuthContextActionTypes.LOGOUT });
    if (preLogoutActionRef.current) preLogoutActionRef.current();
    await clearCache();
  }, [dispatch, clearCache]);

  const setPreLogoutAction = useCallback((action: () => void) => {
    preLogoutActionRef.current = action;
  }, []);

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatchLogin,
        dispatchLogout,
        setPreLogoutAction,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
