import * as Yup from 'yup';
import { commonPasswords } from './commonPasswords';

export const passwordValidationSchema = Yup.string()
  .required('Password must not be empty')
  .min(8, 'Password must be at least 8 characters long')
  .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
  .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
  .matches(/\d/, 'Password must contain at least one number')
  .notOneOf(commonPasswords, 'Password is common and may be easily guessed');

export const newPasswordValidationSchema = Yup.string()
  .required('New Password must not be empty')
  .min(8, 'New Password must be at least 8 characters long')
  .matches(/[!@#$%^&*(),.?":{}|<>]/, 'New Password must contain at least one special character')
  .matches(/[A-Z]/, 'New Password must contain at least one uppercase letter')
  .matches(/\d/, 'New Password must contain at least one number')
  .notOneOf(commonPasswords, 'New Password is common and may be easily guessed')
  //Test required as cannot use multiple "notOneOf" statements in the same validation
  .test('check-against-previous-password', 'New Password cannot be the same as the Current Password', function (value) {
    const { oldPassword } = this.parent;
    return value !== oldPassword;
  });
