//List sourced from https://github.com/danielmiessler/SecLists/blob/master/Passwords/Common-Credentials/10-million-password-list-top-1000000.txt
//A "common password" is defined as in the top 1 million common passwords list, and satisfies our password requirements
export const commonPasswords = [
  'L58jkdjP!',
  'P@ssw0rd',
  '!QAZ2wsx',
  '1qaz!QAZ',
  '4_LiFe',
  'P030710P$E4O',
  '1qaz@WSX',
  'ZAQ!2wsx',
  '!QAZxsw2',
  '%E2%82%AC',
  'NICK1234-rem936',
  'xxPa33bq.aDNA',
  '!QAZ1qaz',
  'g00dPa$$w0rD',
  'Jhon@ta2011',
  'Nloq_010101',
  '1qazZAQ!',
  'zaq1ZAQ!',
  'Ab.1234567',
  'ZAQ!xsw2',
  'Pa$$w0rd',
  'Mosquito@13',
  'onlyOne4-myXworld',
  'Euq8pvHrnpSSdymIZQx+',
  'Dybik.66.',
  'Az.1996.',
  'Art_06.05',
  'Al#kS3!kSj0xX',
  'zaq1!QAZ',
  'Qwerty1!',
  'P@$$w0rd',
  'PnEv_12',
  'Password1!',
  'Ford-1',
  'F5_mK6Vn',
  'eXpl0it|ng',
  'AES-128',
  'abcd!EFG!123',
  '9B2u*c',
  '1.2E+66',
  'ZAQ!1qaz',
  'xx85.eNK7a8M2',
  '$Tatyana09$',
  'Samantha1!',
  '$RFV4rfv',
  '!QAZzaq1',
  '!QAZxsw2#EDCvfr4',
  '!QAZ2ws',
  'Pw_310la',
  'P@ssw0r',
  'P@55w0rd',
  '..oO3xPZokK6',
  'OmL47#yW',
  'NCC-1701',
  'Mif_123',
  'Marco1997!',
  'M3hm3t_k',
  'LOLO-909',
  'Juninho1999@',
  'jack33_lWR9L',
  'Hengsha_2_23',
  'gNdb4b3$',
  'GiASox-291',
  'Gfhjkm_2',
  'Bmw_540_i',
  'bb33BB##',
  'aZiA-J64',
  '5tgbBGT%',
  '..5630E',
  '4c@J&$sS',
  '2wsx#EDC',
  '1qaz@WS',
  '1qaz!QA',
  '1Password!',
  '1.23E+47',
  'ZXCvbn123!',
  'ZGMF-X20',
  'Zachary1!',
  '@YZA6y9aduBadu',
  '$yxH2T',
  '@YSU9e2eXUNupa',
  'ysQN*AXT5oKP',
  'Yr7$jiRO',
  'ynaJAWave$U6Y4',
  'y9Y%aTy3EpYXUQ',
  'xxO03Olo.QHZ2',
  'xx0trl.Ebiq5E',
  'xsw2#EDC',
  'X-rated9437',
  'X-rated5562',
  'X-rated1893',
  '$XeaRoMs8upWyBtS',
  'Ww3Ymb8f$fhr',
  'wtG6Z@A',
  'Wolki1H%ig',
  'wL7320JQ!8',
  'whigginb1-S739RHT8',
  'War3xpansion_',
  'W8STED_R0x',
  'w!2E3B#pszD9fXAv',
  'W0mbatdrug$',
  'VqGbShD5BmMw$Lup',
  'Vixens.comreaper999MOR098GO',
  'VITALINA_1992',
  'VcW19uNO3o!p',
  'v9ActnwQoy$Y',
  'V6#WnsBLDES2!7Zg',
  'Uz3*',
  'UXUdE%U7uqeryG',
  'umyZu@APY7ytYQ',
  'u@eLy2y6u#erY4',
  'Tm3A..M2',
  'Tickled.comreaper999MOR098GO',
  'taQU5ybY5e#YbA',
  't69_XDKx',
  'sUtY8yLaDyhe!A',
  'Super.168',
  'STALKER.1993',
  'S!OsoP8duGDbqEcH',
  'Sorboro@14',
  'Some@1128',
  'Sky!blu3',
  'sk68xzd!3NhY',
  'Sj2323!!',
  'Simpsons1#',
  'Shad0w*10*1',
  'S=#Et!041mAkToeZ',
  'Seriy.290199',
  'SCCUTER_9',
  's5j1Ko6Sa#',
  'REN.2007',
  'RaBe_1968',
  'R9lk7..y7IE.',
  'QWERTY_123',
  '!@QW12qw',
  '*Q_sS886004_',
  '..QfZe5pxdVA',
  '!QAZ2wsx#EDC',
  '!Qaz2wsx',
  'qA@Yf8',
  '!QA2ws3ed',
  'P@ssW0rd',
  'PRAT123*',
  'PML_963',
  'Play-b0y',
  'Password1.',
  'Passw0rd!',
  'Panda-9',
  'PAA-0001',
  'P@55w0r',
  'onlyOne4-eXcesS',
  'Oleg.Kapustin_95',
  'Ocgatk2-Llaveatk2',
  'n.Zp6Wx',
  'NY#77G-f',
  'Number123456789!!!',
  'No_Hack8',
  '??????N?N?12345',
  'Nicole_1',
  'n6mjx7yQ@orw',
  'm#XI!RZYvh681=Fn',
  'Mumi-l0v',
  'Monster_1',
  'Money4me2!',
  'Mark_24',
  'ManicSon-love908',
  'MAGODEOZ_9',
  'Mach1_03',
  'm!6jAnlik0Mt97',
  'M2141-01',
  'LucXakK_00',
  'LT95vv@s',
  'L_h1o2rn',
  'Lena_9Tanya',
  'LazerBoy_1',
  'Lavey-Lavey666',
  'LA%u9E5y2YVuVA',
  'Killer_123',
  'Kauguri98-02',
  'Katya13.12',
  'Katty_Astro1',
  'Karina_1996',
  'K0gzg$f',
  'Jonbm86!',
  'Joed++?8',
  '_JJJDDDl29_',
  'Jhood22-december',
  'Jesus777!',
  'Jerk921@',
  'J3z*rQ',
  'J33p3rs!',
  'iPhone43.',
  'INMDinmd_93',
  'iA!VK#S=72q0UjG4',
  'i05#2VWET!GfCO7M',
  'HXUNS2-M',
];
